<template>
  <div class="root">
    <!-- <div>
      {{ `${totalPrize.toFixed(1)}- ${seat.isWinner}` }}
    </div> -->

    <div style="" class="full_seat" v-if="seat.status != '0'">
      <!-- 头像 -->
      <div class="box">
        <div class="avatar">
          <img :src="seat.avatar|fullPath" alt class="avatarImg" />
          <!-- <img :src="seat.avatar || ''" alt width="30%" height="100%" /> -->
        </div>

        <!-- 座位状态 -->
        <!-- <div class="seatStatus">
          <div v-if="seat.status == '2'">就绪</div>
          <div v-if="seat.status == '1'">未准备</div>
        </div> -->

        <!-- 昵称 -->
        <div class="room_seat_name textover-f">
          <!-- {{ `${seat.nickName}(${seat.playerId})` }} -->
          {{ `${seat.nickName}` }}
        </div>
        <div class="result_box">
          <!-- <div v-if="false" class="result_box"> -->
          <!-- 结算画面 -->
          <div class="pk_result">
            <!-- 加载中 -->
            <div class="center" style="height: 100%">
              <!-- 动画没结束是true 结束了是false -->
            </div>
            <!-- 结算数据界面 -->
            <div>
              <!-- 所有战利品价值 -->
              <div class="money-money">
                <money class="money"></money>
                <countTo
                  v-if="fightRoom.status == 1 || fightRoom.status == 0"
                  :startVal="0"
                  :endVal="countPrice"
                  :duration="1500"
                  :formatter="formatter"
                  :decimals="2"
                >
                </countTo>
                <countTo
                  v-if="fightRoom.status == 2 || fightRoom.status == 3"
                  :startVal="0"
                  :endVal="seat.isWinner ? totalPrize : 0"
                  :duration="1500"
                  :formatter="formatter"
                  :decimals="2"
                >
                </countTo>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 准备按钮 -->
      <!-- <div class="seatReady">
        <div
          class="room_seat_btn _wait center"
          v-if="seat.status == '1' && seat.playerId == loginUserId"
          @click="seatReady()"
        >
          准备
        </div>
        <div
          class="room_seat_btn _wait center"
          v-if="seat.status == '1' && seat.playerId == loginUserId"
          @click="exit()"
        >
          退出
        </div>
      </div> -->

      <!-- 老虎机 -->

      <div class="lotteryRoundsDiv" v-if="fightRoom.status == 1">
        <!-- <div class="lotteryRoundsDiv" v-if="true"> -->
        <lotteryRounds
          :records="fightResult.get(this.playerId).filter((item) => item.boxId)"
          :singlePlayTime="singlePlayTime"
          :currentRound="currentRound"
          :length="length"
          @speedChange="onSpeedChange"
          @allEnd="scrollStop"
          @produceResult="getLotteryResult"
        >
        </lotteryRounds>
      </div>
      <div v-if="fightRoom.status == 2 || fightRoom.status == 3">
        <div v-if="seat.isWinner" class="result_icon win_icon"></div>
        <!-- 失败显示的图标 -->
        <div v-else class="result_icon fail_icon"></div>
      </div>
      <!-- <div v-if="fightRoom.status == 2" class="result_box">
        <div class="pk_result">
          <div class="center" style="height: 100%">
          </div>
          <div>
            <div class="money-money">
              <money class="money"></money>
              <countTo
                :startVal="0"
                :endVal="seat.isWinner ? totalPrize : 0"
                :duration="1500"
                :formatter="formatter"
                :decimals="2"
              >
              </countTo>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div
        v-if="awardsShow"
        class="money-money"
      >
        <money class="money"></money>
        <countTo
          :startVal="0"
          :endVal="playingAwards"
          :duration="1500"
          :formatter="formatter"
          :decimals="2"
        >
        </countTo>
      </div> -->

      <!-- 每一个一个结果 -->
      <!-- <div
        v-if="fightRoom.status == 1"
        style="border: 1px solid white"
        class="result_box"
      > -->
      <!-- <div class="lotteryRes"> -->
      <div
        class="lotteryRes"
        v-if="realTimeResult.length != 0 && fightRoom.status == 1"
        :style="{ height: `${600 - W}px` }"
      >
        <div class="lotteryResItem" v-for="(record, i) in realTimeResult" :key="i">
          <div
            class="img"
            :style="{ 'background-image': `url(${record.ornamentLevelImg})` }"
          >
            <img :src="record.imageUrl|fullPath" alt class="lotteryResImg" />
          </div>
          <div class="result_weapon_name textover-f">
            {{ record.ornamentName }}
          </div>
          <div class="result_weapon_money money-money">
            <money class="money"></money>
            {{ record.ornamentsPrice }}
          </div>
        </div>
      </div>

      <div
        class="lotteryRes"
        v-if="fightRoom.status == 2 || fightRoom.status == 3"
        :style="{ height: `${600 - W}px` }"
      >
        <!-- <div class="lotteryRes" v-if="fightRoom.status == 2" :style="{'height':'616px'}"> -->
        <div class="lotteryResItem" v-for="(record, i) in arr" :key="i">
          <div
            class="img"
            :style="{ 'background-image': `url(${record.ornamentLevelImg})` }"
          >
            <img :src="record.imageUrl|fullPath" alt class="lotteryResImg" />
          </div>
          <div class="result_weapon_name textover-f">
            {{ record.ornamentName }}
          </div>
          <div class="result_weapon_money money-money">
            <money class="money"></money>
            {{ record.ornamentsPrice }}
          </div>
        </div>
      </div>
    </div>

    <!-- <div>
      lotterySeat文档流
    </div> -->
  </div>
</template>

<script>
// window.get
import lotteryRounds from "@/components/pkHome/lotteryRounds.vue";
import countTo from "vue-count-to";

import {
  getUserInfo,
  fightRoomExit,
  // ApiSeatReady, //玩家准备
  // Playthegame, //开始游戏
  // Gameover, //游戏结束
} from "@/api/index";

export default {
  data() {
    return {
      //当前登录用户的id
      loginUserId: "",
      // isWinner: false,
      debugSpeed: 0,
      //老虎机下面实时的结果列表
      realTimeResult: [],
      //奖品价值之和
      playingAwards: 0,
      awardsShow: true,
      num: 0,
      W: 0,
    };
  },

  watch: {},
  methods: {
    exit() {
      fightRoomExit({ fightId: this.fightRoom.id }).then(() => {
        // console.log("fightRoomExit", res);
      });
    },
    formatter() {},
    //准备游戏
    seatReady() {
      this.$emit("seatReady", true);
      // this.isjoin = true;
    },

    getLotteryResult(res) {
      // console.log("老虎机结果", res);
      let r = null;
      // console.log("对战结果列表", this.fightResult);

      for (let record of this.fightResult.get(this.playerId)) {
        if (record.ornamentId == res) {
          this.realTimeResult.unshift(record);
          // console.log("展示结果", this.realTimeResult);
          r = record;
          break;
        }
      }
      // console.log("ornamentsPrice", r);
      this.playingAwards = this.playingAwards + r.ornamentsPrice;

      // this.scrollStop();
    },
    onSpeedChange(speed) {
      this.debugSpeed = speed;
    },
    scrollStop() {
      this.$emit("myAllEnd");
      // this.fightRoom.status = 2
      // this.num++;
      // if (this.currentRound == 0) {
      //   if (this.num == this.fightResult.get(this.playerId).length) {
      //     setTimeout(() => {
      //       this.$emit("myAllEnd");
      //       this.fightRoom.status = 2;
      //     }, 1000);
      //   }
      // } else {

      //   if (
      //     this.num ==
      //     this.fightResult.get(this.playerId).length - this.currentRound + 1
      //   ) {
      //     setTimeout(() => {
      //       this.$emit("myAllEnd");
      //       this.fightRoom.status = 2;
      //     }, 1000);
      //   }
      // }
    },
  },
  props: {
    fightRoom: Object,
    fightResult: Map,
    playerId: Number,
    seat: Object,
    singlePlayTime: Number,
    currentRound: Number,
    svgW: Number,
    length: Number,
  },
  components: {
    lotteryRounds,
    countTo,
  },
  created() {
    // 请求api获取用户信息
    getUserInfo().then((res) => {
      if (res.data.code == 200) {
        this.loginUserId = res.data.data.userId;
      } else {
        this.$message({
          message: "获取用户信息失败,请重新登陆",
          type: "warning",
          customClass: "log_warning",
        });
      }
    });
  },
  beforeMount() {
    // this.time = this.playTime;
  },
  mounted() {
    // console.log('currentRound',this.currentRound);
    // console.log('123',this.geallWard);
    // console.log('playerId',this.playerId);
    // console.log('totalPrize',this.totalPrize);
    // console.log('isEnd',this.isEnd);
    // console.log("this.currentRound", this.currentRound);
    if (this.currentRound != 0 && this.currentRound ) {
      this.realTimeResult = this.fightResult
        .get(this.playerId)
        .slice(0, this.currentRound - 1);
      // console.log("123456", this.realTimeResult);
    }
    // console.log("arr", this.arr);
    if (document.body.clientWidth >= 550) {
      this.W = "234";
    } else {
      this.W = document.body.clientWidth * 0.2;
    }
    //屏幕尺寸变化就重新赋值
    window.onresize = () => {
      return (() => {
        if (document.body.clientWidth >= 550) {
          this.W = "234";
        } else {
          this.W = document.body.clientWidth * 0.2;
        }
      })();
    };
  },
  computed: {
    arr() {
      let a = [];
      let b = [];
      console.log("对战结果", this.fightResult);
      this.fightResult.forEach((value) => {
        a.push(...value);
      });
      a.forEach((item) => {
        if (item.holderUserId == this.playerId) {
          b.push(item);
        }
      });
      return b;
    },
    totalPrize() {
      let total = this.arr.reduce((currentValue, item) => {
        // console.log(item, currentValue);
        return item.ornamentsPrice + currentValue;
      }, 0);
      return total;
    },
    countPrice() {
      let total = this.realTimeResult.reduce((currentValue, item) => {
        // console.log(item, currentValue);
        return item.ornamentsPrice + currentValue;
      }, 0);
      return total;
    },
  },
};
</script>

<style lang="scss" scoped>
.fail_icon {
  background: url("../../../public/fail_2.png") center no-repeat;
  background-size: 110%;
  width: 90%;
  padding-bottom: 70%;
  margin: 0 auto;
  // height:234px ;
  // width: 234px;
  // height: 234px;
  // @media (max-width: 550px) {
  //   width: 44vw;
  //   height: 44vw;
  // }
}
.win_icon {
  background: url("../../../public/win.png") center no-repeat;
  background-size: 110%;
  width: 90%;
  padding-bottom: 70%;
  margin: 0 auto;

  // height: 234px;
  // width: 234px;
  // height: 234px;
  // @media (max-width: 550px) {
  //   width: 44vw;
  //   height: 44vw;
  // }
}
.win_img {
  width: 100%;
}
.result_weapon_money {
  line-height: 4 !important;
}

.lotteryResItem {
  .img {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 10px;
    width: 80%;
    margin: 0 auto;
  }
}
.weapon_img {
  width: 214.5px;
  @media (max-width: 550px) {
    width: 39vw;
  }
}
.full_seat {
  position: relative;
  // border: 1px solid red;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // height: 1400px
  // border: 1px solid red;
  .box {
    width: 100%;
    height: 141.5px;
  }
  .avatar {
    position: relative;
    // border: 1px solid greenyellow;
    width: 100%;
  }
  .avatarImg {
    width: 71.5px;
    height: 71.5px;
    @media (max-width: 550px) {
      width: 13vw;
      height: 13vw;
    }
  }

  .seatStatus {
    // display: none;
  }

  .lotteryRoundsDiv {
    position: relative;
    //border: 1px solid gold;
    // width: 100%;
    height: 99px;

    @media (max-width: 550px) {
      height: 18vw;
    }
    margin: 10px 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    lotteryRounds {
      position: relative;
    }
  }
  .result_items {
    width: 100%;
    height: 62vh;
    overflow: auto;
  }
  .result_box {
    // display: none;
    position: relative;
    // border: 1px white solid;
    width: 100%;
  }
  .lotteryRes {
    position: relative;
    width: 100%;
    padding-top: 10px;
    // border: 1px solid red;
    // height: 74vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: #25f484;
      border-radius: 5px;
    }

    @media (max-width: 550px) {
      &::-webkit-scrollbar {
        width: 2px;
      }
    }
    .lotteryResItem {
      position: relative;
      // border: 1px white solid;
      // height: 26%;
      margin-bottom: 5px;
    }
  }

  .room_seat_btn {
    margin: 3% auto 0;
    font-size: 18px;
    width: 70%;
    height: 35px;

    @media (max-width: 550px) {
      margin: 7% auto 0;

      width: 70px;
      height: 25px;
      font-size: 13px;
    }
  }

  ._wait {
    background: linear-gradient(
      360deg,
      rgba(37, 244, 132, 0.5) 0%,
      rgba(37, 244, 132, 0.25) 100%
    );

    //border: 1px solid #25f484;
  }
}
.seatReady {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.lotteryResImg {
  // @media (max-width: 550px) {
  //   width: 26vw;
  //   height: 26vw;
  // }
  // width: 143px;
  // height: 143px;
  width: 100%;
  max-width: 130px;
  margin: 0 auto;
}
</style>
